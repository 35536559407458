import React from 'react'
import './TranslateComponent.scss'
import i18next from "i18next";
import {translate} from "react-i18next";
import {Dropdown, Icon} from "semantic-ui-react";
import {Store} from "../../../store";

const TranslateComponent = (props) => {

    const { state } = React.useContext(Store);
    const [defaultLanguage, setDefaultLanguage] = React.useState('en');



    React.useEffect(() => {
        if(state.init.site.hasOwnProperty('defaultLanguage')){
            if(!sessionStorage.getItem('lang')){
                getLangObj('siteLang');
            } else {
                getLangObj('sessionLang')
            }
        } else {
           getLangObj('defaultEn')
        }

    }, [state]);

    const getLangObj =(value) =>{
        switch (value) {
            case 'defaultEn':
                let defaultEng = {slug :"en" ,text :'English'};
                changeLanguage(defaultEng);
                break;
            case 'siteLang':
                let siteLang = i18next.options.resources.langs.find(e => { return (e.slug === state.init.site.defaultLanguage) });
                changeLanguage(siteLang);
                break;
            case 'sessionLang':
                let sessionLang = i18next.options.resources.langs.find(e => { return (e.slug === sessionStorage.getItem('lang')) });
                changeLanguage(sessionLang);
                break;
        }
    }
    const changeLanguage = (language) => {
        if(language){
            setDefaultLanguage(language);
            i18next.changeLanguage(language.slug);
        }else {
            getLangObj('defaultEn')
        };

    };


    return(
        state.module.modules.language.show && <div className="language-component">
            <div className='langauge-item'>
                <Icon name='world' />
                <Dropdown className="dropdown-text" text={`${defaultLanguage.text}`}>
                    <Dropdown.Menu>
                        {
                            (i18next.options.resources.langs).map((item, index) => {
                                return (<Dropdown.Item key={index} className='textColor' text={item.text} onClick={() =>
                                {
                                    i18next.changeLanguage(item.slug);
                                    setDefaultLanguage(item);
                                    sessionStorage.setItem('lang',item.slug);

                                }} />)
                            })}

                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='langauge-item-text'>
                {props.t("language")}
            </div>
        </div>
    )
};

export default translate("translations")(TranslateComponent);
