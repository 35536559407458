import React from 'react'
import { Store } from "../../../store";
import {Icon} from "semantic-ui-react";
import './Username.scss'
import {translate} from "react-i18next";

const UsernameComponent = (props) => {
    const { state } = React.useContext(Store);

    return (
       state.module.modules.username.show &&
       <div className='usernameProp'>
            <div>
                <h4><Icon name='user' />{state.init.user.username}</h4>
            </div>
            <div>
                <p>{props.t('playername')}</p>
            </div>
        </div>

    )
};

export default translate("translations")(UsernameComponent);
