import React from 'react'
import './MachineName.scss'
import {Store} from "../../../store";
import {Icon} from "semantic-ui-react";
import {translate} from "react-i18next";

const MachineNameComponent = (props) => {
    const { state } = React.useContext(Store);

    return (

        state.module.modules.terminal_name.show &&
        <div>
             <div className='MachineNameProp'>
                 <h4><Icon name='tag' />{state.init.terminal.name}</h4>
             </div>
             <div className='MachineNameProp'>
                 {props.t("machine_name")}
             </div>
        </div>
    )
};

export default translate("translations")(MachineNameComponent);
