const colors = [
    { rangeStart: 0, rangeEnd: 5, hexColor: '#01FF05' },
    { rangeStart: 6, rangeEnd: 10, hexColor: '#0EF205' },
    { rangeStart: 11, rangeEnd: 15, hexColor: '#1CE404' },
    { rangeStart: 16, rangeEnd: 20, hexColor: '#29D704' },
    { rangeStart: 21, rangeEnd: 25, hexColor: '#36C904' },
    { rangeStart: 26, rangeEnd: 30, hexColor: '#44BC04' },
    { rangeStart: 31, rangeEnd: 35, hexColor: '#51AE03' },
    { rangeStart: 36, rangeEnd: 40, hexColor: '#5FA103' },
    { rangeStart: 41, rangeEnd: 45, hexColor: '#6C9403' },
    { rangeStart: 46, rangeEnd: 50, hexColor: '#798603' },
    { rangeStart: 51, rangeEnd: 55, hexColor: '#877902' },
    { rangeStart: 56, rangeEnd: 60, hexColor: '#946B02' },
    { rangeStart: 61, rangeEnd: 65, hexColor: '#A15E02' },
    { rangeStart: 66, rangeEnd: 70, hexColor: '#AF5102' },
    { rangeStart: 71, rangeEnd: 75, hexColor: '#BC4301' },
    { rangeStart: 76, rangeEnd: 80, hexColor: '#CA3601' },
    { rangeStart: 81, rangeEnd: 85, hexColor: '#D72801' },
    { rangeStart: 86, rangeEnd: 90, hexColor: '#E41B01' },
    { rangeStart: 91, rangeEnd: 95, hexColor: '#F20D00' },
    { rangeStart: 96, rangeEnd: 100, hexColor: '#FF0000' }
];

export default colors;