import React from 'react';

import { translate, Trans } from "react-i18next";
const NotFound = (props) => {
    return (
        <div>
            <h1 style={{textAlign:'center'}}>{props.t('unauthorized')}</h1>
        </div>
    )
}

export default translate("translations")(NotFound);