import React from 'react';
import {Store} from '../../store';
import './AliasScreen.scss';
// const electron = window.require('electron');
// const {ipcRenderer} = electron;

const AliasScreen = () => {
    const {state} = React.useContext(Store);
    /*React.useEffect(() => {
      let syncBtn  = document.querySelector('#syncBtn');
      let asyncBtn = document.querySelector('#asyncBtn');
    
      let replyDiv = document.querySelector('#reply');
    
      syncBtn.addEventListener('click', () => {
        let reply = ipcRenderer.sendSync('synMessage','A sync message to main');
        replyDiv.innerHTML = reply;
      });
    
      asyncBtn.addEventListener('click', () => {
        ipcRenderer.send('aSynMessage','A async message to main')
      });
    
      ipcRenderer.on('asynReply1', (event, args) => {
        console.log('event => ',event);
        console.log('value => ',args);
        replyDiv.innerHTML = args;
      });
    }, [])*/
    return (
        <div className="alias-screen">
            <p>
              {
                state.init.alias
              }   
            </p>   
        </div>
    )
}

export default AliasScreen;
