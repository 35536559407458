import React from 'react';
import {Button, Icon} from 'semantic-ui-react'
//import './DirectionButton.scss'

const DirectionButton = (props) => {

    const ScrollDown = () => {
        window.scrollBy(0, 50);
    }

    const ScrollUp = () => {
        window.scrollBy(0, -50);
    }    

    return (
        <div className="direction-button-component">
            {
                props.direction === 'back' && 
                <Button>
                    <Icon name='arrow left' />
                </Button>
            }
            {
                props.direction === 'forward' && 
                <Button>
                    <Icon name='arrow right' />
                </Button>
            }                        
            {
                props.direction === 'up' &&
                <Button onClick={ScrollUp}>
                    <Icon name='arrow up' />
                </Button>
            }                        
            {
                props.direction === 'down' && 
                <Button onClick={ScrollDown}>
                    <Icon name='arrow down' />
                </Button>
            }                        
        </div>
    )
}

export default DirectionButton;