import React from 'react';

export const Store = React.createContext();

const initialState = {
    games: [],
    ticket: {},
    init: {}
};

function reducer(state, action) {
    switch (action.type) {
        case 'ADD_GAMES': 
            return {...state, games: [...action.payload]};
        case 'SET_TICKET_INFO':
            return {...state, ticket: {...action.payload}};
        case 'SET_INIT_INFO':
            return {...state, init: {...action.payload}};
        case 'CHECK_MODULE':
            return {...state ,module :{...action.payload}};
        default: 
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const value = {
        state,
        dispatch
    }

    return (
        <Store.Provider value={value}>
            {props.children}
        </Store.Provider>
    )
}
