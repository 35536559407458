import React, {useState, useContext, useEffect} from 'react';
import {Store} from '../../store';
import './terminalNameScreen.scss';
import {Icon} from 'semantic-ui-react';
import { translate } from "react-i18next";
const electron = window.require('electron');
const {ipcRenderer} = electron;

const TerminalNameScreen = (props) => {
    const {state} = useContext(Store);
    const [version, setVersion] = useState(0);
    const year = new Date().getFullYear();


    useEffect(() => {
        if (ipcRenderer !== false) {
            ipcRenderer.send('version-request', "version");            
            ipcRenderer.on('version-request-reply', function (event, args){
                setVersion(args);
            });
        }
    }, [version])



    return (
        <div className='terminal-name-screen'>
            <div className="terminal-name">
                <Icon name='computer' />
                <h1>{state.init.terminal.name} </h1>
            </div>
            <h4>Spinmatic Entertainment © {year} | v{version} {props.t('all_rights_reserved')}</h4>
        </div>
    )
}

export default translate("translations")(TerminalNameScreen);
