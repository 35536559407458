import React, { useEffect, useState } from 'react';
import ApiService from '../../../services/ApiService';
import { Store } from '../../../store';
import './GameList.scss';
import DirectionButton from '../../modules/directionButton/DirectionButton'
import { useHistory } from 'react-router-dom'
import HeaderComponent from "../../modules/header/Header";
import ModalComponent from '../../modal/ModalComponent';
import Sidebar from "../../modules/sidebar/Sidebar";
import IPCPrint from '../../../services/Print';
const JsBarcode = require('jsbarcode');

const GameList = () => {
    const { state, dispatch } = React.useContext(Store);
    let currentGame = 0;
    let history = useHistory();
    const terminalID = localStorage.getItem('terminal_id');
    const [openPayoutModal, setOpenPayoutModal] = useState(false)
    let currentGameDiv = '';

    useEffect(() => {
        // console.log(state);
        const result = ApiService('/all-games', { terminal_id: terminalID });
        result.then(resp => {
            if (resp.status === 200 && (Object.keys(resp.data.data).length > 0 || resp.data.data.length > 0)) {
                dispatch({
                    type: 'ADD_GAMES',
                    payload: resp.data.data.group[0].games
                })
            } else {
                location.reload();
            }
        });
    }, []);

    useEffect(() => {
        keyDownKeyboard();

        document.addEventListener("keydown", keyDownKeyboard, false);

        return () => {
            document.removeEventListener("keydown", keyDownKeyboard);
        }
    });

    function keyDownKeyboard(event = null) {
        if (event === null) {
            if (state.games.length > 0) {
                state.games.map(game => {
                    let prevCurrentGameDiv = document.getElementById(`${game.id}`)
                    prevCurrentGameDiv.classList.remove('add-border'); // removes all yellow borders if state changes
                })
                let firstGame = state.games[0];
                currentGameDiv = document.getElementById(`${firstGame.id}`)
                currentGameDiv.classList.add('add-border'); // adds yellow border to first game in the beginning
            }
        } else {
            if (String(event.key).toLowerCase() === 'r') {
                if (state.games.length - 1 > currentGame) {
                    ++currentGame;
                    movingRightLeft('-');
                }
            } else if (String(event.key).toLowerCase() === 'x') {
                if (currentGame > 0) {
                    --currentGame;
                    movingRightLeft('+');
                }
            } else if (String(event.key).toLowerCase() === 'm') {
                let game = findCurrentGame();
                clickedGame(game);
            } else if (String(event.key).toLowerCase() === 'c') {
                const {balance, currency, username} = state.init.user;
                const {id, name} = state.init.terminal;
                
                // setOpenPayoutModal(true);
                 
                const payoutReq = ApiService('/payment/ticket', {'terminal_id': id, 'amount' : balance,'all': 1});
                payoutReq.then(resp => {
                    if (resp.status === 200) {
                        let {code} = resp.data.data;
                        if (code !== undefined) {
                            let printAmount = `${parseFloat(balance)} ${currency}`;
                            IPCPrint(name, text2Base64Barcode(code.toString()), printAmount, username);
                        }                                                
                    }
                })
            }
        }
        if (currentGameDiv) {
            currentGameDiv.scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    }

    function text2Base64Barcode (text) {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, text);
        return canvas.toDataURL("image/jpg");
    };

    function findCurrentGame() {
        let currentGameID = currentGameDiv.getAttribute("id");
        return state.games.find(game => {
            return game.id === Number(currentGameID);
        });
    }

    function clickedGame(game) {
        history.push({
            pathname: '/play',
            state: {
                game: game
            }
        });
    }

    function closePayoutModal() {
        setOpenPayoutModal(false);
    }

    function movingRightLeft(operator) {
        if (operator === '-') {
            state.games.map((game, index) => {
                if (index === currentGame) {
                    currentGameDiv = document.getElementById(`${game.id}`)
                    currentGameDiv.classList.add('add-border');
                }
                if (index === currentGame - 1) {
                    let prevCurrentGameDiv = document.getElementById(`${game.id}`)
                    prevCurrentGameDiv.classList.remove('add-border')
                }
            })
        } else {            
            state.games.map((game, index) => {
                if (index === currentGame) {
                    currentGameDiv = document.getElementById(`${game.id}`)
                    currentGameDiv.classList.add('add-border');
                }        
                if (index === currentGame + 1) {
                    let prevCurrentGameDiv = document.getElementById(`${game.id}`)
                    prevCurrentGameDiv.classList.remove('add-border')
                }
            })
        }
    }

    return (
        <div className="game-list-component">
            {
                state.init.user !== null && state.init.user !== undefined &&
                <>
                    {/*state.module.modules.sidebar.show &&
                    <Sidebar myState={{state}}/>*/}

                    {/*state.module.modules.header.show &&
                    <HeaderComponent myState={state} />*/}
                    <div className="games">
                        {
                            state.games.map(game => {
                                return (
                                    <div key={game.id} className="game-div">
                                        <div className="default-game-style" id={game.id} onClick={() => clickedGame(game)}>
                                            <img src={game.thumbnail} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        /*state.games.length > 0 &&
                        <div className="direction-buttons">
                            <DirectionButton direction="up" />
                            <DirectionButton direction="down" />
                        </div>*/
                    }
                    {
                        openPayoutModal && <ModalComponent openModal={openPayoutModal} closeModal={closePayoutModal} />
                    }
                </>
            }
        </div>
    )
}

export default GameList;
