import React from 'react'
import './Sidebar.scss'
import { Store } from "../../../store";
import i18next from "i18next";
import { translate } from "react-i18next";
import ModalComponent from '../../modal/ModalComponent'
import UsernameComponent from '../username/Username'
import MachineNameComponent from "../machineName/MachineName";
import BalanceComponent from "../balance/Balance";
import TranslateComponent from "../translate/TranslateComponent"
import LogoComponent from "../logo/Logo";
import JackpotComponent from '../jackpot/Jackpot';
import { Icon } from "semantic-ui-react";




const SideBarComponent = (props) => {
    const { state } = React.useContext(Store);
    const [openModal, setOpenModal] = React.useState(false);
    function closeModal() {
        setOpenModal(false);
    }

    return (
        state.module.modules.sidebar.show &&
        <div className={`sidebar ${state.module.modules.sidebar.position === 'left' ? 'sidebar-left' : 'sidebar-right'}`}>
            <div>
                {state.module.modules.sidebar.contents.payout && <ModalComponent openModal={openModal} closeModal={closeModal} />}
                {state.module.modules.header.contents.logo &&<LogoComponent/>}
                {state.module.modules.sidebar.contents.username && <UsernameComponent />}
                {state.module.modules.sidebar.contents.balance && <BalanceComponent />}
                {state.module.modules.sidebar.contents.terminal_name && <MachineNameComponent />}
                {state.module.modules.sidebar.contents.payout && <a className='payout-item' onClick={() => { setOpenModal(true) }}>
                    <h4><Icon name='money bill alternate outline' />{props.t("payout")}</h4>
                    <p style={{ marginTop: "-15px" }}>Money</p>
                </a>}
                {state.module.modules.sidebar.contents.language && <TranslateComponent />}    
                {state.module.modules.sidebar.contents.jackpot && <JackpotComponent />}
            </div>            
        </div> 

    )
};
export default translate("translations")(SideBarComponent);
