import React from 'react'
import { Loader, Dimmer } from 'semantic-ui-react'

const LoaderInverted = (props) => {
    return (
        <Dimmer active inverted>
            <Loader active />
        </Dimmer>
    )
}


export default LoaderInverted;