import React from 'react'
import { Store } from "../../../store";
import {Icon} from "semantic-ui-react";
import './Balance.scss'
import {translate} from "react-i18next";

const BalanceComponent = (props) => {
    const { state } = React.useContext(Store);

    return (
       state.module.modules.balance.show &&
       <div className='BalanceProp'>
           <div>
               <h4>{state.init.user.balance} {state.init.user.currency}</h4>
           </div>
           <div>
               <p>{props.t('balance')}</p>
           </div>

        </div>
    )
};

export default translate("translations")(BalanceComponent);
